import React, { useEffect, useContext, useState, useRef } from 'react'
import { Container, Flex, Box ,Text,  Button} from 'theme-ui'
import ContentContainer from '@solid-ui-components/ContentContainer'
import Divider from '@solid-ui-components/Divider'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import useForm from '@helpers/useForm'
import { FormContext } from '@solid-ui-components/ContentForm'
import httpService from '../../../../../../site/src/service/httpService'

import loadsGif from '../../../assets/loads.gif'
import { ModalContext } from '@solid-ui-components/Modal'

const styles = {
  combo: {
    p: 0.4,
    pl: 3,
    flex: [`100%`, null, null, 1],
    border: 'transparent'
  },

  bxStyleAd: {
    width: '30%',
    px: [1]
  },

  bxStyleBirim: {
    width: '25%',
    px: [2]
  },
  // wrapper2: {
  //   flex: [`100%`, null, null, 1],
  //   minWidth: 500,
  //   maxWidth: [`none`, null, null, 675],
  //   p: 2,
  //   height: "550px"
  // },
  wrapper1: {
    flex: [`100%`, null, null, 1],
    minWidth: 450,
    maxWidth: [`none`, null, null, 450],
    p: 2,
    textAlign: `center`
  },
  wrapper2: {
    flex: [`100%`, null, null, 1],
    minWidth: 1000,
    maxWidth: [`none`, null, null, 1000],
    p: 2
  },
  card: {
    overflow: `hidden`,

    margin: "15px"
  },
  content: {
    alignItems: `stretch`,
    flexDirection: [`row`, null, null, `column`],
    height: `full`
  },
  body: {
    display: `flex`,
    flexDirection: `column`,
    justifyContent: `center`,
    flex: 1,
    p: 4
  },
  footerWrapper: {
    alignItems: `center`
  },
  postInfo: {
    flex: 1,
    flexWrap: `wrap`,
    justifyContent: `space-between`,
    color: `omega`,
    ml: 3
  },
  imageWrapper: {
    textAlign: `center`,
    position: `relative`,
    display: `block`,
    height: `full`
  },
  image: {
    display: [`none`, `block`],
    height: `full`,
    bg: `omegaLighter`,
    borderRadius: `default`,
    borderWidth: 0,
    minHeight: `11rem`,
    div: {
      p: `0 !important`
    }
  },
  avatar: {
    size: 42,
    bg: `omegaLighter`,
    borderRadius: `full`,
    borderStyle: `solid`,
    borderWidth: `md`,
    borderColor: `omegaLighter`,
    boxSizing: `content-box`,
    img: {
      objectPosition: 'top center !important'
    }
  },

  textVisible: {
    visible: 'false',
  },


  modalContent2: {
    borderRadius: `lg`,
    bg: `contentBg`,
    maxWidth: `container`,
    overflow: `hidden`,
    py: 4,
    px: 4
  },
  close: {

    position: `absolute`,
    // top: [3, null, -5],
    // left: [2, null, null, -5],
    size: [`icon.sm`, null, `icon.md`],
    zIndex: 99,
    width: 50,
    height: 50

  },
  wrappermlz: {
    // flex: [`100%`, null, null, 1],
    // minWidth: 500,
    // maxWidth: [`none`, null, null, 675],
    p: 0.5
  }
}



const Davetiye = ({ ...props }) => {

  const { handleSubmit, submitting, success } = useForm()
  const { formValues, setFormValues } = useContext(FormContext)
  const { setActiveModal } = useContext(ModalContext)

  const [btnID, setbtnID] = useState("");
  const formId = "frmDavetiye"

  const [dt, setDt] = useState(null);

  const [ldg, setLdg] = useState(false);


  useEffect(async () => {
    if (typeof window !== 'undefined') {
      setDt(null);
      let id = window.location.search?.replace("?", "");

      if (id.length == 24) {
        httpService.post("UnLocked/DavetiyeGetir", { params: id }).then((res) => {
          if (res.DogruMu) {
            setDt(res.Data);
          } else {
            setDt(null);
          }
          setLdg(true);
        }).catch((err) => {
          setDt(null);
          setLdg(true);
        });
      } else{
        window.location.assign("/");
      }
    }
  }, []);


  useEffect(() => {
    return () =>
      success !== undefined &&
      submitting === false &&
      setFormValues({
        ...formValues,
        [formId]: {}
      })
  }, [success, submitting, setFormValues])

  const onChange = e => {
    setFormValues({
      ...formValues,
      [formId]: {
        ...formValues?.[formId],
        [e.target.name]: e.target.value
      }
    })


  }






  const onSubmit = async e => {
    e.preventDefault();

    if (btnID == "btnKabulEt") {
      let id = window.location.search?.replace("?", "");

      if (id.length == 24) {
        httpService.post("UnLocked/DavetiyeKabulEt", { params: id }).then((res) => {
          if (res.DogruMu) {
            setDt(res.Data);
          } else {

          }
        }).catch((err) => {

        });
      }else{
        window.location.assign("/");
      }

    }
  }




  return (
    <Container>

      {ldg == false ? <div className='dv-l'>
        <Divider space="3" />
        <img src={loadsGif} alt="online box die cutting loading" style={{ width: "200px", objectFit: "contain" }} />
        <Divider space="5" />
      </div> :
        <form
          onSubmit={onSubmit}
          method='POST'
        >
          {dt && (
            <Flex sx={{ flexWrap: `wrap`, justifyContent: `center`, m: -1 }}>
              <Box sx={styles.wrapper1}>
                <Box sx={{ flex: 1, py: [1, 1], px: [2, 3], textAlign: `center` }}>
                  <Text as='h3' color='black'>
                    Hoşgeldiniz {dt.AdSoyad}
                  </Text> </Box>
                <ContentContainer
                  content={{
                    variant: "cards.interactive",
                  }}
                  variant='cards.interactive'
                  sx={styles.card} >

                  <Box sx={{ margin: "10px" }}>
                    {dt.KayitDurumu == 1 ?
                      <Box sx={{ flex: 1, py: [1, 1], px: [2, 3] }}>
                        {dt.DavetKabulEdildiMi == true ?
                          <>
                            <Text sx={{ marginTop: 15, marginLeft: 15, marginRight: 15 }} color="beta">{dt.DavetEdenKullaniciAdSoyad} kullanıcımızın</Text>
                            <br />
                            <Text sx={{ marginLeft: 15, marginRight: 15 }} color="beta">beraber çalışma davetini kabul ettiniz</Text>
                            <br />
                            <Text sx={{ marginLeft: 15, marginRight: 15 }} color="beta">Sistemimize giriş yapabilirisiniz</Text>
                          </> :
                          <>
                            <Text sx={{ marginTop: 15, marginLeft: 15, marginRight: 15 }} color="beta">{dt.DavetEdenKullaniciAdSoyad} kullanıcımız</Text>
                            <br />
                            <Text sx={{ marginLeft: 15, marginRight: 15 }} color="beta">sizi kendi çalışma ekibine davet etmektedir</Text>
                          </>
                        }
                      </Box> :
                      <Box sx={{ flex: 1, py: [1, 1], px: [2, 3] }}>
                        <Text sx={{ marginTop: 15, marginLeft: 15, marginRight: 15 }} color="beta">Bu davetiye silinmiştir</Text>

                      </Box>}
                    <Divider space="3" />
                    <Box sx={{ flex: 1, py: [1, 1], px: [2, 3] }}>
                      {/* <Text color="alpha">genel mesaj</Text> */}
                      {dt.KayitDurumu == 1 && dt.DavetKabulEdildiMi==false ?
                        <div className="login-btn">
                          <Button type="submit"
                            id={"btnKabulEt"}
                            aria-label="davetiye kabul et"
                            index={3}
                            onClick={e => setbtnID(e.target.id)}
                          >
                            Daveti Kabul Et
                          </Button>
                        </div> : <></>
                      }
                    </Box>
                    <Divider space="3" />
                  </Box>
                </ContentContainer>

                <Box>
                  <Text key={`lblicolcumesaj`} as='span' color='alpha' sx={{

                  }} > <h2 onClick={e => {
                    setActiveModal('authentication')
                  }} style={{ cursor: "pointer", textDecoration: "underline" }}>

                      {"Giriş Yap"} </h2>      </Text>
                </Box>


                <Box>
                  <Text key={`lblicolcumesaj`} as='span' color='grey' sx={{

                  }} > <h3 onClick={e => {
                    window.location.assign("/");
                  }} style={{ cursor: "pointer", textDecoration: "underline" }}>

                      {"Ana Sayfaya Git"} </h3>      </Text>
                </Box>
              </Box>

            </Flex>
          )}
        </form >
      }
    </Container >
  )
}

export default WithDefaultContent(Davetiye)


