import React from 'react'
import { graphql } from 'gatsby'
import KtLayout from '@solid-ui-blocks/KtLayout/KtLayout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import Davetiye from '@solid-ui-blocks/Davetiye/Davetiye'


const DavetiyePage = props => {

  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)
 
  return (
    <KtLayout prp={props} cnt={content} lightmi={false} >
      <Seo title='Davetiye ' />
      <Divider space='5' /> 
      <Davetiye/>
      <Divider space='5' /> 
      <Divider space='5' /> 
      <Divider space='5' /> 
      <Divider space='5' /> 
    </KtLayout>
  )
}

export const query = graphql`
  query innerpageDavetiyePageBlockContent { 
    allBlockContent(filter: { page: { in: ["homepage/saas-v2", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    } 
  }
`


export default DavetiyePage
